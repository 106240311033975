import React from "react";
import { createRoot } from "react-dom/client";
import Gantt from "./gantt";

const App = () => {
  return (
    <React.StrictMode>
      <Gantt />
    </React.StrictMode>
  );
};

document.addEventListener("DOMContentLoaded", () => {
  const root = createRoot(document.getElementById("root"));
  root.render(<App />);
});

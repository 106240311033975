import React from "react";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

const GnattChart: React.FC = () => {
  const [tasks, setTasks] = React.useState([]);

  React.useEffect(() => {
    const fetchTasks = async () => {
      try {
        const response = await fetch("/api/v1/tasks");
        const data = await response.json();
        setTasks(data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      }
    };

    fetchTasks();
  }, []);

  if (!tasks.length) {
    return <div>Loading...</div>;
  }
  return (
    <div className="gantt-container">
      <h1>Gantt Chart</h1>
      <Gantt
        timeStep={1000 * 60 * 60 * 24}
        listCellWidth={""}
        columnWidth={100}
        tasks={tasks.map((task) => {
          const { id, name, startDate, endDate, progress } = task;
          return {
            start: new Date(startDate),
            end: new Date(endDate),
            name,
            id,
            type: "task",
            progress: progress,
          };
        })}
        // viewMode={view}
        onDateChange={(task) => {
          const { id, start, end, progress } = task;
          fetch(`/api/v1/tasks/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              task: {
                startDate: start.toISOString(),
                endDate: end.toISOString(),
                progress: progress,
              },
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Task updated:", data);
            })
            .catch((error) => {
              console.error("Error updating task:", error);
            });
        }}
        // onTaskDelete={onTaskDelete}
        onProgressChange={(task) => {
          const { id, start, end, progress } = task;
          fetch(`/api/v1/tasks/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              task: {
                startDate: start.toISOString(),
                endDate: end.toISOString(),
                progress: progress,
              },
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Task updated:", data);
            })
            .catch((error) => {
              console.error("Error updating task:", error);
            });
        }} // onDoubleClick={onDblClick}
        // onClick={onClick}
      />
    </div>
  );
};

export default GnattChart;
